<template>
  <div>
    <v-layout row wrap class="mb-10 d-flex justify-center">
      <v-flex
        xs12
        md5
        class="elevation-10 text-center white mb-10"
        style="border-radius: 30px; padding: 50px"
      >
        <h1 style="font-size: 38px; font-weight: 700">
          <span class="underline-text">Welcome</span> Back
        </h1>
        <p class="mb-10 mt-5">Please enter your email & password</p>
        <div style="padding: 0px 100px">
          <v-text-field
            single-line
            outlined
            prepend-inner-icon="mdi-email-outline"
            label="Your Email"
            hide-details
            v-model="email"
            style="border-radius: 8px"
          ></v-text-field>
          <v-text-field
            single-line
            outlined
            type="password"
            prepend-inner-icon="mdi-lock"
            label="Your Password"
            hide-details
            class="mt-5 mb-3"
            v-model="password"
            style="border-radius: 8px"
          ></v-text-field>
          <p class="ma-0 text-left"><u>Forgot Password</u></p>
          <v-progress-circular
            indeterminate
            color="teal"
            v-if="loading"
          ></v-progress-circular>
          <v-btn
            large
            rounded
            class="teal white--text pl-10 pr-10 mt-5"
            @click="login"
            v-else
            >Log In</v-btn
          >
        </div>

        <div class="d-flex align-center justify-space-around mt-10">
          <div>
            <img src="@/assets/divider.svg" style="max-width: 100%" />
          </div>
          <p class="ma-0">Or continue with</p>
          <div>
            <img src="@/assets/divider.svg" style="max-width: 100%" />
          </div>
        </div>
        <div>
          <v-btn
            text
            class="pa-4 mt-5"
            style="
              border-radius: 7px;
              text-transform: capitalize;
              border: 0.49px solid #8a8a8a;
              background: #fff;
              backdrop-filter: blur(22.049999237060547px);
            "
            ><img
              src="@/assets/google.svg"
              class="mr-3"
              style="width: 28px"
            />Sign in with Google</v-btn
          >
        </div>
        <p class="mt-10 mb-0">
          Don't have an account ?
          <span @click="signup"
            ><u><b>Sign Up</b></u></span
          >
        </p>
      </v-flex>
    </v-layout>
  </div>
</template>

<style scoped>
.underline-text {
  position: relative;
  display: inline-block;
  color: #4aabab;
}
.underline-text::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: -20px;
  right: 0;
  stroke: 3px;
  width: 220px;
  height: 30px;
  background-image: url("../../assets/welcome.png");
  /* filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25)); */
  background-repeat: no-repeat;
  background-size: cover;
}
.v-text-field__details {
  margin-bottom: 0;
}
</style>

<script>
export default {
  data() {
    return {
      loading: false,
      email: "",
      password: "",
    };
  },
  methods: {
    signup() {
      this.$router.push("/signup");
    },
    login() {
      this.loading = true;
      setTimeout(() => {
        let user = {
          name: "AS",
          email: "sanath@axyzglobal.com",
        };
        if (
          this.email == "admin@cryptotax.co.in" &&
          this.password == "password"
        ) {
          this.$store.commit("SET_USER", user);
          this.loading = false;
          this.$toastr.s(
            "You have successfully logged in to your account.",
            "Login Success"
          );
          this.$router.push("/dashboard");
        }else{
          this.$toastr.e(
            "Please check your email and password.",
            "Login Failed"
          );
          this.loading = false
          this.password = ""
        }
      }, 1000);
    },
  },
};
</script>